import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetSales = () => {
  return {
    type: actionTypes.INIT_GET_SALES,
    loading_sales: true
  };
};

const successGetSales = data => {
  return {
    type: actionTypes.SUCCSESS_GET_SALES,
    loading_sales: false,
    all_sales: data
  };
};

const failGetSales = error => {
  return {
    type: actionTypes.FAIL_GET_SALES,
    loading_sales: false,
    error_sales: error
  };
};

export const getSales = () => {
  const config = createConfig();
  return dispatch => {
    dispatch(initGetSales());
    axios
      .get("sales", config)
      .then(res => dispatch(successGetSales(res.data.data)))
      .catch(err => dispatch(failGetSales(err.message)));
  };
};

//role sale:

const initGetSale = () => {
  return {
    type: actionTypes.INIT_GET_SALE,
    loading_sale: true
  };
};
const successGetSale = data => {
  return {
    type: actionTypes.SUCCSESS_GET_SALE,
    loading_sale: false,
    sale_data: data
  };
};
const failGetSale = error => {
  return {
    type: actionTypes.FAIL_GET_SALE,
    loading_sale: false,
    sale_error: error
  };
};

export const getSaleDetail = id => {
  const config = createConfig();
  return dispatch => {
    dispatch(initGetSale());
    axios
      .get("sales/view/" + id, config)
      .then(res => dispatch(successGetSale(res.data.data)))
      .catch(err => console.log(err.message));
  };
};
