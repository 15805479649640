import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_roles: false,
  all_roles: [],
  inactive_roles: [],
  error_roles: null,

  adding_role_error: null,
  //
  loading_role: false,
  role_data: null,
  role_error: null
};

const initGetRoles = (state, action) => {
  return {
    ...state,
    loading_roles: action.loading_roles
  };
};
const successGetRoles = (state, action) => {
  return {
    ...state,
    loading_roles: action.loading_roles,
    all_roles: action.all_roles,
    inactive_roles: action.inactive_roles
  };
};
const failGetRoles = (state, action) => {
  return {
    ...state,
    loading_roles: action.loading_roles,
    error_roles: action.error_roles
  };
};
//
const failAddRole = (state, action) => {
  return {
    ...state,
    adding_role_error: action.adding_role_error
  };
};

const initGetRole = (state, action) => {
  return {
    ...state,
    loading_role: action.loading_role
  };
};
const successGetRole = (state, action) => {
  return {
    ...state,
    loading_role: action.loading_role,
    role_data: action.role_data
  };
};
const failGetRole = (state, action) => {
  return {
    ...state,
    loading_role: action.loading_role,
    role_error: action.vendor_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_ROLES:
      return initGetRoles(state, action);
    case actionTypes.SUCCSESS_GET_ROLES:
      return successGetRoles(state, action);
    case actionTypes.FAIL_GET_ROLES:
      return failGetRoles(state, action);
    //
    case actionTypes.FAIL_ADD_ROLE:
      return failAddRole(state, action);
    //
    case actionTypes.INIT_GET_ROLE:
      return initGetRole(state, action);
    case actionTypes.SUCCSESS_GET_ROLE:
      return successGetRole(state, action);
    case actionTypes.FAIL_GET_ROLE:
      return failGetRole(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
