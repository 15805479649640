import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_banners: false,
  active_banners: [],
  inactive_banners: [],
  error_banners: null,
  //
  loading_banner: false,
  banner_detail: null,
  banner_error: null
};

const initGetBanners = (state, action) => {
  return {
    ...state,
    loading_banners: action.loading_banners
  };
};
const successGetBanners = (state, action) => {
  return {
    ...state,
    loading_banners: action.loading_banners,
    active_banners: action.active_banners,
    inactive_banners: action.inactive_banners
  };
};
const failGetBanners = (state, action) => {
  return {
    ...state,
    loading_banners: action.loading_banners,
    error_banners: action.error_banners
  };
};

const initGetBanner = (state, action) => {
  return {
    ...state,
    loading_banner: action.loading_banner
  };
};
const successGetBanner = (state, action) => {
  return {
    ...state,
    loading_banner: action.loading_banner,
    banner_detail: action.banner_detail
  };
};
const failGetBanner = (state, action) => {
  return {
    ...state,
    loading_banner: action.loading_banner,
    banner_error: action.banner_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_BANNERS:
      return initGetBanners(state, action);
    case actionTypes.SUCCSESS_GET_BANNERS:
      return successGetBanners(state, action);
    case actionTypes.FAIL_GET_BANNERS:
      return failGetBanners(state, action);
    //
    case actionTypes.INIT_GET_BANNER:
      return initGetBanner(state, action);
    case actionTypes.SUCCSESS_GET_BANNER:
      return successGetBanner(state, action);
    case actionTypes.FAIL_GET_BANNER:
      return failGetBanner(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
