import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_applications: false,
  data_applications: [],
  error_applications: null,
  pagination_applicatoin: null,
  remote_params: {
    sort: "desc",
    search: "",
    page: 1,
  },
};

const initGetApplications = (state, action) => {
  return {
    ...state,
    loading_applications: action.loading_applications,
  };
};
const successGetApplications = (state, action) => {
  return {
    ...state,
    loading_applications: action.loading_applications,
    data_applications: action.data_applications,
    pagination_applicatoin: action.pagination_applicatoin,
  };
};
const failGetApplications = (state, action) => {
  return {
    ...state,
    loading_applications: action.loading_applications,
    error_applications: action.error_applications,
  };
};

const updateKycParams = (state, action)=>{
  return {
    ...state,
    remote_params:{...action.params}
  }
}

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_APPLICATIONS:
      return initGetApplications(state, action);
    case actionTypes.SUCCSESS_GET_APPLICATIONS:
      return successGetApplications(state, action);
    case actionTypes.FAIL_GET_APPLICATIONS:
      return failGetApplications(state, action);
    case actionTypes.UPDATE_KYC_PARAMS:
      return updateKycParams(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
