import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetBanners = () => {
  return {
    type: actionTypes.INIT_GET_BANNERS,
    loading_banners: true
  };
};

const successGetBanners = data => {
  return {
    type: actionTypes.SUCCSESS_GET_BANNERS,
    loading_banners: false,
    active_banners: data.active,
    inactive_banners: data.inactive
  };
};

const failGetBanners = error => {
  return {
    type: actionTypes.FAIL_GET_BANNERS,
    loading_banners: false,
    error_banners: error
  };
};

export const getBanners = () => {
  const token = localStorage.getItem("idToken");

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    dispatch(initGetBanners());
    axios
      .get("banner", config)
      .then(res => dispatch(successGetBanners(res.data.data)))
      .catch(err => dispatch(failGetBanners(err.message)));
  };
};

//Delete Category

export const deleteBanner = id => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);

  return dispatch => {
    axios
      .post("banner", formData, config)
      .then(res => dispatch(getBanners()))
      .catch(err => console.log(err.message));
  };
};

// add Category

export const addBanner = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("sort", 1);
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return dispatch => {
    axios
      .post("banner", formData, config)
      .then(res => {
        dispatch(getBanners());
        redirect("/banners");
      })
      .catch(err => console.log(err.message, err.response));
  };
};

//category detail:

const initGetBnner = () => {
  return {
    type: actionTypes.INIT_GET_BANNER,
    loading_banner: true
  };
};
const successGetBnner = data => {
  return {
    type: actionTypes.SUCCSESS_GET_BANNER,
    loading_banner: false,
    banner_detail: data
  };
};
const failGetBnner = error => {
  return {
    type: actionTypes.FAIL_GET_BANNER,
    loading_banner: false,
    banner_error: error
  };
};

export const getBnnerDetail = id => {
  const config = createConfig();
  return dispatch => {
    axios
      .get("banner/view/" + id, config)
      .then(res => dispatch(successGetBnner(res.data.data)))
      .catch(err => console.log(err.message));
  };
};
