import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetSubCategories = () => {
  return {
    type: actionTypes.INIT_GET_SUB_CATEGORIES,
    loading_sub_categories: true
  };
};

const successGetSubCategories = (data, mainCateId) => {
  return {
    type: actionTypes.SUCCSESS_GET_SUB_CATEGORIES,
    loading_sub_categories: false,
    category_name: data.category_name,
    category_id: mainCateId,
    active_sub_categories: data.active,
    inactive_sub_categories: data.inactive
  };
};

const failGetSubCategories = error => {
  return {
    type: actionTypes.FAIL_GET_SUB_CATEGORIES,
    loading_sub_categories: false,
    error_sub_categories: error
  };
};

export const getSubCategories = mainCateId => {
  const token = localStorage.getItem("idToken");

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    dispatch(initGetSubCategories());
    axios
      .get("category/sub/index/" + mainCateId, config)
      .then(res => dispatch(successGetSubCategories(res.data.data, mainCateId)))
      .catch(err => dispatch(failGetSubCategories(err.message)));
  };
};

//sub category detail:

const initGetSubCategory = () => {
  return {
    type: actionTypes.INIT_GET_SUB_CATEGORY,
    loading_sub_category: true
  };
};
const successGetSubCategory = data => {
  return {
    type: actionTypes.SUCCSESS_GET_SUB_CATEGORY,
    loading_sub_category: false,
    sub_category_detail: data
  };
};
const failGetSubCategory = error => {
  return {
    type: actionTypes.FAIL_GET_SUB_CATEGORY,
    loading_sub_category: false,
    sub_category_error: error
  };
};

export const getSubCategoryDetail = id => {
  const config = createConfig();
  return dispatch => {
    axios
      .get("category/sub/view/" + id, config)
      .then(res => dispatch(successGetSubCategory(res.data.data)))
      .catch(err => console.log(err.message));
  };
};

// add sub Category

export const addSubCategory = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("sort", 1);
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));
  for (let [key, val] of formData.entries()) console.log(key, val);

  return dispatch => {
    axios
      .post("category/sub", formData, config)
      .then(res => {
        redirect({ pathname: "/sub-categories", state: data.category_id });
      })
      .catch(err => console.log(err.message, err.response));
  };
};

//Delete sub Category

export const deleteSubCategory = (id, mainCateId) => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);

  return dispatch => {
    axios
      .post("category/sub", formData, config)
      .then(res => dispatch(getSubCategories(mainCateId)))
      .catch(err => console.log(err.message));
  };
};
