import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_sales: false,
  all_sales: [],
  error_sales: null,
  loading_sale: false,
  sale_data: null,
  sale_error: null
};

const initGetSales = (state, action) => {
  return {
    ...state,
    loading_sales: action.loading_sales
  };
};
const successGetSales = (state, action) => {
  return {
    ...state,
    loading_sales: action.loading_sales,
    all_sales: action.all_sales,
    inactive_roles: action.inactive_roles
  };
};
const failGetSales = (state, action) => {
  return {
    ...state,
    loading_sales: action.loading_sales,
    error_sales: action.error_sales
  };
};
//
const initGetSale = (state, action) => {
  return {
    ...state,
    loading_sale: action.loading_sale
  };
};
const successGetSale = (state, action) => {
  return {
    ...state,
    loading_sale: action.loading_sale,
    sale_data: action.sale_data
  };
};
const failGetSale = (state, action) => {
  return {
    ...state,
    loading_sale: action.loading_sale,
    sale_error: action.vendor_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_SALES:
      return initGetSales(state, action);
    case actionTypes.SUCCSESS_GET_SALES:
      return successGetSales(state, action);
    case actionTypes.FAIL_GET_SALES:
      return failGetSales(state, action);
    //
    case actionTypes.INIT_GET_SALE:
      return initGetSale(state, action);
    case actionTypes.SUCCSESS_GET_SALE:
      return successGetSale(state, action);
    case actionTypes.FAIL_GET_SALE:
      return failGetSale(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
