import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_sub_categories: false,
  category_name: null,
  category_id: null,
  active_sub_categories: [],
  inactive_sub_categories: [],
  error_sub_categories: null,
  //
  loading_sub_category: false,
  sub_category_detail: null,
  sub_category_error: null
};

const initGetSubCategories = (state, action) => {
  return {
    ...state,
    loading_sub_categories: action.loading_sub_categories
  };
};
const successGetSubCategories = (state, action) => {
  return {
    ...state,
    loading_sub_categories: action.loading_sub_categories,
    category_name: action.category_name,
    category_id: action.category_id,
    active_sub_categories: action.active_sub_categories,
    inactive_sub_categories: action.inactive_sub_categories
  };
};
const failGetSubCategories = (state, action) => {
  return {
    ...state,
    loading_sub_categories: action.loading_sub_categories,
    error_sub_categories: action.error_sub_categories
  };
};
// sub category detail
const initGetSubCategory = (state, action) => {
  return {
    ...state,
    loading_sub_category: action.loading_sub_category
  };
};
const successGetSubCategory = (state, action) => {
  return {
    ...state,
    loading_sub_category: action.loading_sub_category,
    sub_category_detail: action.sub_category_detail
  };
};
const failGetSubCategory = (state, action) => {
  return {
    ...state,
    loading_sub_category: action.loading_sub_category,
    sub_category_error: action.sub_category_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_SUB_CATEGORIES:
      return initGetSubCategories(state, action);
    case actionTypes.SUCCSESS_GET_SUB_CATEGORIES:
      return successGetSubCategories(state, action);
    case actionTypes.FAIL_GET_SUB_CATEGORIES:
      return failGetSubCategories(state, action);
    //
    case actionTypes.INIT_GET_SUB_CATEGORY:
      return initGetSubCategory(state, action);
    case actionTypes.SUCCSESS_GET_SUB_CATEGORY:
      return successGetSubCategory(state, action);
    case actionTypes.FAIL_GET_SUB_CATEGORY:
      return failGetSubCategory(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
