import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";
import { logout } from "./authActions";

const initGetProfile = () => {
  return {
    type: actionTypes.INIT_GET_PROFILE,
    loading_profile: true,
    loading: true
  };
};

const successGetProfile = data => {
  return {
    type: actionTypes.SUCCSESS_GET_PROFILE,
    loading_profile: false,
    loading: false,
    user: {
      name: data.name,
      email: data.email,
      active: data.active,
      role_id: data.role_id,
      ...data.profile
    }
  };
};

const failGetProfile = error => {
  return {
    type: actionTypes.FAIL_GET_PROFILE,
    loading_profile: false,
    error_profile: error
  };
};

export const getProfile = () => {
  const config = createConfig();
  return dispatch => {
    dispatch(initGetProfile());
    axios
      .get("profile", config)
      .then(res => dispatch(successGetProfile(res.data.data)))
      .catch(err => {
        dispatch(failGetProfile(err.response));
        dispatch(logout());
      });
  };
};

// update profile
const initUpdateProfile = () => {
  return {
    type: actionTypes.INIT_UPDATE_PROFILE,
    update_profile: true
  };
};
const successUpdateProfile = () => {
  return {
    type: actionTypes.SUCCSESS_UPDATE_PROFILE,
    update_profile: false,
    update_profile_sucess: true
  };
};
const errorUpdateProfile = error => {
  return {
    type: actionTypes.FAIL_UPDATE_PROFILE,
    update_profile: false,
    update_profile_error: error
  };
};

export const updateProfile = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return dispatch => {
    dispatch(initUpdateProfile());
    axios
      .post("profile", formData, config)
      .then(res => {
        dispatch(getProfile());
        dispatch(successUpdateProfile());
      })
      .catch(err => dispatch(errorUpdateProfile(err.response.data.errors)));
  };
};

export const updateProfilePassword = (password, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("password", password);
  formData.append("_method", "PUT");

  return dispatch => {
    dispatch(initUpdateProfile());
    axios
      .post("profile/password", formData, config)
      .then(res => {
        dispatch(getProfile());
        dispatch(successUpdateProfile());
        redirect("/user-profile/personal-information");
      })
      .catch(err => dispatch(errorUpdateProfile(err.response)));
  };
};
