import { createStore, applyMiddleware, compose } from "redux";
import axios from "axios";

import thunk from "redux-thunk";
import reducer from "./rootReducer";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const logger = store => {
  return next => {
    return action => {
      console.log("MiddlerWare", action);
      const result = next(action);
      console.log("MidlleWare-2", store.getState());
      return result;
    };
  };
};

const store = createStore(reducer, compose(applyMiddleware(logger, thunk)));
const { dispatch } = store;

export default store;
