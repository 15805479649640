/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import AuthPage from "./modules/Auth/AuthPage";
import { LayoutSplashScreen } from "../_metronic/layout";
import { getProfile } from "../redux/action";

export function Routes() {
  const dispatch = useDispatch();
  const { user, loading } = useSelector(state => state.auth);
  const [isAuth, setIsAuth] = useState(
    localStorage.getItem("idToken") !== null ?? null
  );
  const history = useHistory();

  useEffect(() => {
    const token = localStorage.getItem("idToken");
    if (token) {
      setIsAuth(true);
      if (!user) dispatch(getProfile());
    } else setIsAuth(false);
  }, [user]);

  return loading ? (
    <LayoutSplashScreen visible={true} />
  ) : (
    <Switch>
      {isAuth && user ? (
        <Layout>
          <BasePage />
        </Layout>
      ) : !isAuth ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
