import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_users: false,
  all_users: [],
  error_users: null,
  //
  adding_user_error: null,
  //
  loading_user: false,
  user_profile: null,
  user_error: null
};

const initGetUsers = (state, action) => {
  return {
    ...state,
    loading_users: action.loading_users
  };
};
const successGetUsers = (state, action) => {
  return {
    ...state,
    loading_users: action.loading_users,
    all_users: action.all_users,
    ...action.data,
    ...action.other
  };
};
const failGetUsers = (state, action) => {
  return {
    ...state,
    loading_users: action.loading_users,
    error_users: action.error_users
  };
};
//
const failAddUser = (state, action) => {
  return {
    ...state,
    adding_user_error: action.adding_user_error
  };
};
//
const initGetUser = (state, action) => {
  return {
    ...state,
    loading_user: action.loading_user
  };
};
const successGetUser = (state, action) => {
  return {
    ...state,
    loading_user: action.loading_user,
    user_profile: action.user_profile
  };
};
const failGetUser = (state, action) => {
  return {
    ...state,
    loading_user: action.loading_user,
    user_error: action.user_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_USERS:
      return initGetUsers(state, action);
    case actionTypes.SUCCSESS_GET_USERS:
      return successGetUsers(state, action);
    case actionTypes.FAIL_GET_USERS:
      return failGetUsers(state, action);
    //
    case actionTypes.FAIL_ADD_USER:
      return failAddUser(state, action);
    //
    case actionTypes.INIT_GET_USER:
      return initGetUser(state, action);
    case actionTypes.SUCCSESS_GET_USER:
      return successGetUser(state, action);
    case actionTypes.FAIL_GET_USER:
      return failGetUser(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
