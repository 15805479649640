import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetDashoard = () => {
  return {
    type: actionTypes.INIT_GET_DASHBOARD,
    loading_dashboard: true
  };
};

const successGetDashoard = data => {
  return {
    type: actionTypes.SUCCSESS_GET_DASHBOARD,
    loading_dashboard: false,
    sales: data.sales,
    product: data.product,
    status: data.status
  };
};

const failGetDashoard = error => {
  return {
    type: actionTypes.FAIL_GET_DASHBOARD,
    loading_dashboard: false,
    error_dashboard: error
  };
};

export const getDashoard = () => {
  const token = localStorage.getItem("idToken");

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    dispatch(initGetDashoard());
    axios
      .get("dashboard", config)
      .then(res => dispatch(successGetDashoard(res.data)))
      .catch(err => dispatch(failGetDashoard(err.message)));
  };
};
