//Auth:
export const INIT_AUTH = "INIT_AUTH";
export const SUCCESS_AUTH = "SUCCESS_AUTH";
export const FAIL_AUTH = "FAIL_AUTH";

//Categories:
export const INIT_GET_CATEGORIES = "INIT_GET_CATEGORIES";
export const SUCCSESS_GET_CATEGORIES = "SUCCESS_GET_CATEGORIES";
export const FAIL_GET_CATEGORIES = "FAIL_GET_CATEGORIES";
//category
export const INIT_GET_CATEGORY = "INIT_GET_CATEGORY";
export const SUCCSESS_GET_CATEGORY = "SUCCESS_GET_CATEGORY";
export const FAIL_GET_CATEGORY = "FAIL_GET_CATEGORY";
//sub categories:
export const INIT_GET_SUB_CATEGORIES = "INIT_GET_SUB_CATEGORIES";
export const SUCCSESS_GET_SUB_CATEGORIES = "SUCCSESS_GET_SUB_CATEGORIES";
export const FAIL_GET_SUB_CATEGORIES = "FAIL_GET_SUB_CATEGORIES";
//sub categoriy:
export const INIT_GET_SUB_CATEGORY = "INIT_GET_SUB_CATEGORY";
export const SUCCSESS_GET_SUB_CATEGORY = "SUCCSESS_GET_SUB_CATEGORY";
export const FAIL_GET_SUB_CATEGORY = "FAIL_GET_SUB_CATEGORY";
//Vendors:
export const INIT_GET_VENDORS = "INIT_GET_VENDORS";
export const SUCCSESS_GET_VENDORS = "SUCCSESS_GET_VENDORS";
export const FAIL_GET_VENDORS = "FAIL_GET_VENDORS";
//add vendor
export const INIT_ADD_VENDOR = "INIT_ADD_VENDOR";
export const SUCCSESS_ADD_VENDOR = "SUCCSESS_ADD_VENDOR";
export const FAIL_ADD_VENDOR = "FAIL_ADD_VENDOR";
//vendor
export const INIT_GET_VENDOR = "INIT_GET_VENDOR";
export const SUCCSESS_GET_VENDOR = "SUCCSESS_GET_VENDOR";
export const FAIL_GET_VENDOR = "FAIL_GET_VENDOR";
//customers
export const INIT_GET_CUSTOMERS = "INIT_GET_CUSTOMERS";
export const SUCCSESS_GET_CUSTOMERS = "SUCCSESS_GET_CUSTOMERS";
export const FAIL_GET_CUSTOMERS = "FAIL_GET_CUSTOMERS";
//customer
export const INIT_GET_CUSTOMER = "INIT_GET_CUSTOMER";
export const SUCCSESS_GET_CUSTOMER = "SUCCSESS_GET_CUSTOMER";
export const FAIL_GET_CUSTOMER = "FAIL_GET_CUSTOMER";
//users
export const INIT_GET_USERS = "INIT_GET_USERS";
export const SUCCSESS_GET_USERS = "SUCCSESS_GET_USERS";
export const FAIL_GET_USERS = "FAIL_GET_USERS";
//add user
export const INIT_ADD_USER = "INIT_ADD_USER";
export const SUCCSESS_ADD_USER = "SUCCSESS_ADD_USER";
export const FAIL_ADD_USER = "FAIL_ADD_USER";
//user
export const INIT_GET_USER = "INIT_GET_USER";
export const SUCCSESS_GET_USER = "SUCCSESS_GET_USER";
export const FAIL_GET_USER = "FAIL_GET_USER";
//Roles
export const INIT_GET_ROLES = "INIT_GET_ROLES";
export const SUCCSESS_GET_ROLES = "SUCCSESS_GET_ROLES";
export const FAIL_GET_ROLES = "FAIL_GET_ROLES";
//add role:
export const INIT_ADD_ROLE = "INIT_ADD_ROLE";
export const SUCCSESS_ADD_ROLE = "SUCCSESS_ADD_ROLE";
export const FAIL_ADD_ROLE = "FAIL_ADD_ROLE";
// role
export const INIT_GET_ROLE = "INIT_GET_ROLE";
export const SUCCSESS_GET_ROLE = "SUCCSESS_GET_ROLE";
export const FAIL_GET_ROLE = "FAIL_GET_ROLE";
//sales
export const INIT_GET_SALES = "INIT_GET_SALES";
export const SUCCSESS_GET_SALES = "SUCCSESS_GET_SALES";
export const FAIL_GET_SALES = "FAIL_GET_SALES";
//sale
export const INIT_GET_SALE = "INIT_GET_SALE";
export const SUCCSESS_GET_SALE = "SUCCSESS_GET_SALE";
export const FAIL_GET_SALE = "FAIL_GET_SALE";
//profile
export const INIT_GET_PROFILE = "INIT_GET_PROFILE";
export const SUCCSESS_GET_PROFILE = "SUCCSESS_GET_PROFILE";
export const FAIL_GET_PROFILE = "FAIL_GET_PROFILE";
//update profile:
export const INIT_UPDATE_PROFILE = "INIT_UPDATE_PROFILE";
export const SUCCSESS_UPDATE_PROFILE = "SUCCSESS_UPDATE_PROFILE";
export const FAIL_UPDATE_PROFILE = "FAIL_UPDATE_PROFILE";
//Dashboard:
export const INIT_GET_DASHBOARD = "INIT_GET_DASHBOARD";
export const SUCCSESS_GET_DASHBOARD = "SUCCSESS_GET_DASHBOARD";
export const FAIL_GET_DASHBOARD = "FAIL_GET_DASHBOARD";
//Banners:
export const INIT_GET_BANNERS = "INIT_GET_BANNERS";
export const SUCCSESS_GET_BANNERS = "SUCCSESS_GET_BANNERS";
export const FAIL_GET_BANNERS = "FAIL_GET_BANNERS";
//Banner:
export const INIT_GET_BANNER = "INIT_GET_BANNER";
export const SUCCSESS_GET_BANNER = "SUCCSESS_GET_BANNER";
export const FAIL_GET_BANNER = "FAIL_GET_BANNER";
//Banner:
export const INIT_GET_PRODUCTS = "INIT_GET_PRODUCTS";
export const SUCCSESS_GET_PRODUCTS = "SUCCSESS_GET_PRODUCTS";
export const FAIL_GET_PRODUCTS = "FAIL_GET_PRODUCTS";
//KYC Applications:
export const INIT_GET_APPLICATIONS = "INIT_GET_APPLICATIONS";
export const SUCCSESS_GET_APPLICATIONS = "SUCCSESS_GET_APPLICATIONS";
export const FAIL_GET_APPLICATIONS = "FAIL_GET_APPLICATIONS";
export const UPDATE_KYC_PARAMS = "UPDATE_KYC_PARAMS";
