/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { getRoles } from "../../../../../redux/action";

export function AsideMenuList({ layoutProps }) {
  const disptch = useDispatch();
  const { all_roles } = useSelector((state) => state.roles);
  const { user } = useSelector((state) => state.auth);
  // const [userRole, setUserRole] = useState(0);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  useEffect(() => {
    disptch(getRoles());
  }, []);
  // useEffect(() => { if (user) setUserRole(user.role) }, [user])
  // console.log(userRole)
  return (
    user && (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}
          <li
            className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                />
              </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}

          {//super admin
          user.role_id === 1 && (
            <>
              {/* Authoriztion */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white">Authoriztion</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/roles", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/roles">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("media/svg/icons/Tools/Spatula.svg")}
                    />
                  </span>
                  <span className="menu-text">Roles</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
            </>
          )}

          {//
          [1, 2, 3].includes(user.role_id) && (
            <>
              {/* Catalog */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white">Management</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {/* */}
              {[1, 2].includes(user.role_id) && (
                <>
                  {/*begin::1 Level*/}
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      "/users",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="/users">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Users</span>
                    </NavLink>
                    <div className="menu-submenu">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li
                          className="menu-item menu-item-parent"
                          aria-haspopup="true"
                        >
                          <span className="menu-link">
                            <span className="menu-text">Users</span>
                          </span>
                        </li>
                        {/*end::1 Level*/}
                        {/*begin::2 Level*/}
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "users/all-users"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to={{
                              pathname: "/users/all-users",
                              state: "all_users",
                            }}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">All Users</span>
                          </NavLink>
                        </li>
                        {/*end::2 Level*/}
                        {/*begin::2 Level*/}
                        {all_roles.map((role) => (
                          <li
                            key={role.id}
                            className={`menu-item ${getMenuItemActive(
                              "users/" + role.slug
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to={{
                                pathname: "users/" + role.slug,
                                state: role.slug,
                              }}
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">{role.name}</span>
                            </NavLink>
                          </li>
                        ))}
                        {/*end::2 Level*/}

                        {/*begin::2 Level*/}
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/users/inactive"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to={{
                              pathname: "users/inactive",
                              state: "inactive",
                            }}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Inactive</span>
                          </NavLink>
                        </li>
                        {/*end::2 Level*/}
                      </ul>
                    </div>
                  </li>
                  {/*end::1 Level*/}
                </>
              )}
              {/* */}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/vendors", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/vendors">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Thunder.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Vendors</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/verification",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/verification">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "media/svg/icons/General/Shield-protected.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Vendors Verification</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/customers",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/customers">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                    />
                  </span>
                  <span className="menu-text">Customers</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/* Catalog */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white">Catalog</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {[1, 2].includes(user.role_id) && (
                <>
                  {/*begin::1 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/categories",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/categories">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Ticket.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Categories</span>
                    </NavLink>
                  </li>
                  {/*end::1 Level*/}
                </>
              )}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/banners", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/banners">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}
                    />
                  </span>
                  <span className="menu-text">Banners</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              {/* Catalog */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white">Shopping</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}
              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/sales", false)}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sales">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")}
                    />
                  </span>
                  <span className="menu-text">Sales</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}
              {/*begin::1 Level*/}
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/products",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/products">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")}
                    />
                  </span>
                  <span className="menu-text">Products</span>
                </NavLink>
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Products</span>
                      </span>
                    </li>
                    {/*end::1 Level*/}
                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/products/published"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to={{
                          pathname: "/products/published",
                          state: "published",
                        }}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Published</span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/products/unpublished"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to={{
                          pathname: "/products/unpublished",
                          state: "unpublished",
                        }}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Unpublished</span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </div>
              </li>
              {/*end::1 Level*/}

              {/* Catalog */}
              {/* begin::section */}
              <li className="menu-section ">
                <h4 className="menu-text text-white">Preloved</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>
              {/* end:: section */}

              {/*begin::1 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/preloved-sales",
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/preloved-sales">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/ATM.svg")}
                    />
                  </span>
                  <span className="menu-text">Sales</span>
                </NavLink>
              </li>
              {/*end::1 Level*/}

              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/products",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/products">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Shopping/Box1.svg")}
                    />
                  </span>
                  <span className="menu-text">Products</span>
                </NavLink>
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Products</span>
                      </span>
                    </li>
                    {/*end::1 Level*/}
                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/preloved-products/published"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to={{
                          pathname: "/preloved-products/published",
                          state: "published",
                        }}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Published</span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}

                    {/*begin::2 Level*/}
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/preloved-products/unpublished"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to={{
                          pathname: "/preloved-products/unpublished",
                          state: "unpublished",
                        }}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Unpublished</span>
                      </NavLink>
                    </li>
                    {/*end::2 Level*/}
                  </ul>
                </div>
              </li>
              {/*end::1 Level*/}
            </>
          )}
          {/* ####################################
##########################################
########################################### */}

          {/* Applications */}
          {/* begin::section */}
          {/* <li className="menu-section ">
          <h4 className="menu-text text-white">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
          {/* end:: section */}

          {/*begin::1 Level*/}
          {/* <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li> */}
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
    )
  );
}
