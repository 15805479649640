import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetRoles = () => {
  return {
    type: actionTypes.INIT_GET_ROLES,
    loading_roles: true
  };
};

const successGetRoles = data => {
  return {
    type: actionTypes.SUCCSESS_GET_ROLES,
    loading_roles: false,
    all_roles: data.active,
    inactive_roles: data.inactive
  };
};

const failGetRoles = error => {
  return {
    type: actionTypes.FAIL_GET_ROLES,
    loading_roles: false,
    error_roles: error
  };
};

export const getRoles = () => {
  const config = createConfig();
  return dispatch => {
    dispatch(initGetRoles());
    axios
      .get("role", config)
      .then(res => dispatch(successGetRoles(res.data.data)))
      .catch(err => dispatch(failGetRoles(err.message)));
  };
};
//Delete Role
export const deleteRole = id => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);

  return dispatch => {
    axios
      .post("role", formData, config)
      .then(res => dispatch(getRoles()))
      .catch(err => console.log(err.message));
  };
};
//add Role
const initAddRole = () => {
  return {
    type: actionTypes.INIT_ADD_ROLE,
    adding_role: true
  };
};
const successAddRole = () => {
  return {
    type: actionTypes.SUCCSESS_ADD_ROLE,
    adding_role: false,
    adding_role_sucess: true
  };
};
const errorAddRole = error => {
  return {
    type: actionTypes.FAIL_ADD_ROLE,
    adding_role: false,
    adding_role_error: error
  };
};

export const addRole = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return dispatch => {
    dispatch(initAddRole());
    axios
      .post("role", formData, config)
      .then(res => {
        dispatch(getRoles());
        dispatch(successAddRole());
        redirect("/roles");
      })
      .catch(err => dispatch(errorAddRole(err.response.data.errors)));
  };
};

//role detail:

const initGetRole = () => {
  return {
    type: actionTypes.INIT_GET_ROLE,
    loading_role: true
  };
};
const successGetRole = data => {
  return {
    type: actionTypes.SUCCSESS_GET_ROLE,
    loading_role: false,
    role_data: data
  };
};
const failGetRole = error => {
  return {
    type: actionTypes.FAIL_GET_ROLE,
    loading_role: false,
    role_error: error
  };
};

export const getRoleDetail = id => {
  const config = createConfig();
  return dispatch => {
    axios
      .get("vendor/view/" + id, config)
      .then(res => dispatch(successGetRole(res.data.data)))
      .catch(err => console.log(err.message));
  };
};
