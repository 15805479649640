/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router";

export function SuperAdmin({ className, widgetHeight = "175px" }) {
  const history = useHistory();

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
          <div className="mr-2">
            <h3 className="font-weight-bolder">Manage Roles</h3>
            <div className="text-dark-50 font-size-lg mt-2">
              Control user group and roles
            </div>
          </div>
          <a
            className="btn btn-primary font-weight-bold py-3 px-6"
            onClick={e => history.push("/roles")}
          >
            Start Now
          </a>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
