/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomToggler, DropdownMenu1 } from "../../dropdowns";
import { toAbsoluteUrl } from "../../../_helpers";
import { Fragment } from "react";

export function RecentList({ className, list }) {
  return (
    <>
      {/* begin::List Widget 14 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Products Sold
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2">
          {list &&
            list.map(({ total, product, product_id }) => {
              if (product) {
                return (
                  <Fragment key={product_id}>
                    {/* begin::Item */}
                    <div className="d-flex flex-wrap align-items-center mb-10">
                      {/* begin::Symbol */}
                      <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                        {/* <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url('${toAbsoluteUrl(
                              product.thumbnail != null
                                ? product.thumbnail
                                : "/media/no-image-placeholder.png"
                            )}')`,
                          }}></div> */}
                      </div>
                      {/* end::Symbol */}

                      {/* begin::Title */}
                      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                        <a
                          href="#"
                          className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
                        >
                          {product.name}
                        </a>
                        <span className="text-muted font-weight-bold font-size-sm my-1">
                          Price: RM {product.price}
                        </span>
                      </div>
                      {/* end::Title */}

                      {/* begin::Info */}
                      <div className="d-flex align-items-center py-lg-0 py-2">
                        <div className="d-flex flex-column text-right">
                          <span className="text-dark-75 font-weight-bolder font-size-h4">
                            {total}
                          </span>
                          <span className="text-muted font-size-sm font-weight-bolder">
                            Sold
                          </span>
                        </div>
                      </div>
                      {/* end::Info */}
                    </div>
                    {/* end::Item */}
                  </Fragment>
                );
              }
            })}
        </div>
        {/* end::Body */}
      </div>
      {/* end::List Widget 14 */}
    </>
  );
}
