import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetProducts = () => {
  return {
    type: actionTypes.INIT_GET_PRODUCTS,
    loading_products: true
  };
};

const successGetProducts = data => {
  return {
    type: actionTypes.SUCCSESS_GET_PRODUCTS,
    loading_products: false,
    data_products: data,
    error_products: null
  };
};

const failGetProducts = error => {
  return {
    type: actionTypes.FAIL_GET_PRODUCTS,
    loading_products: false,
    error_products: error
  };
};

export const getProducts = published => {
  let endpoint = "product";

  if (!published) endpoint = "product/unpublished";

  const token = localStorage.getItem("idToken");
  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    dispatch(initGetProducts());
    axios
      .get(endpoint, config)
      .then(res => dispatch(successGetProducts(res.data.data)))
      .catch(err => dispatch(failGetProducts(err.message)));
  };
};

//update product

export const updateProduct = data => {
  const config = createConfig();

  return dispatch => {
    axios
      .put("product", data, config)
      .then(res => {
        dispatch(getProducts(data.published === 0 ? true : false));
      })
      .catch(err => console.log(err.message, err.response));
  };
};
