import { combineReducers } from "redux";

import authReducer from "./reducer/authReducer";
import categoriesReducer from "./reducer/categoriesReducer";
import subCategoriesReducer from "./reducer/sub_categoriesReducer";
import vendorReducer from "./reducer/vendorReducer";
import customersReducer from "./reducer/customersReducer";
import usersReducer from "./reducer/usersReducer";
import rolesReducer from "./reducer/rolesReducer";
import salesReducer from "./reducer/salesReducer";
import dashboardReducer from "./reducer/dashboardReducer";
import bannersReducer from "./reducer/bannersReducer";
import productsReducer from "./reducer/productsReducer";
import kycReducer from "./reducer/kycReducer";

const reducer = combineReducers({
  auth: authReducer,
  categories: categoriesReducer,
  subCategories: subCategoriesReducer,
  vendors: vendorReducer,
  customers: customersReducer,
  users: usersReducer,
  roles: rolesReducer,
  sales: salesReducer,
  dashboard: dashboardReducer,
  banners: bannersReducer,
  products: productsReducer,
  kyc: kycReducer
});

export default reducer;
