import * as actionTypes from "../action/actionTypes";

const initState = {
  loading: false,
  user: null,
  error: null,
  //
  update_profile: false,
  update_profile_sucess: false,
  update_profile_error: null
};

const initAuth = (state, action) => {
  return {
    ...state,
    loading: action.loading
  };
};
const successAuth = (state, action) => {
  return {
    ...state,
    loading: action.loading_profile,
    user: action.user
  };
};
const failAuth = (state, action) => {
  return {
    ...state,
    loading: action.loading,
    error: action.error
  };
};
//
const initUpdateProfile = (state, action) => {
  return {
    ...state,
    update_profile: action.update_profile
  };
};
const successUpdateProfile = (state, action) => {
  return {
    ...state,
    update_profile: action.update_profile,
    update_profile_sucess: action.update_profile_sucess
  };
};
const errorUpdateProfile = (state, action) => {
  return {
    ...state,
    update_profile: action.update_profile,
    update_profile_error: action.update_profile_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_AUTH:
      return initAuth(state, action);
    case actionTypes.SUCCESS_AUTH:
      return successAuth(state, action);
    case actionTypes.SUCCSESS_GET_PROFILE:
      return successAuth(state, action);
    case actionTypes.INIT_GET_PROFILE:
      return initAuth(state, action);
    case actionTypes.FAIL_AUTH:
      return failAuth(state, action);
    //
    case actionTypes.INIT_UPDATE_PROFILE:
      return initUpdateProfile(state, action);
    case actionTypes.SUCCSESS_UPDATE_PROFILE:
      return successUpdateProfile(state, action);
    case actionTypes.FAIL_UPDATE_PROFILE:
      return errorUpdateProfile(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
