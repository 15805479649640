import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";

const AddRoles = lazy(() => import("./modules/catalog/add_role"));
const Roles = lazy(() => import("./modules/catalog/roles"));
const Verification = lazy(() => import("./modules/catalog/kycVerification"));

const AddBanners = lazy(() => import("./modules/catalog/add_banner"));
const Banners = lazy(() => import("./modules/catalog/banners"));

const Sales = lazy(() => import("./modules/catalog/sales"));

const AddUsers = lazy(() => import("./modules/management/add_user"));
const Users = lazy(() => import("./modules/management/users"));
const ViewCustomers = lazy(() => import("./modules/management/customer"));
const Customers = lazy(() => import("./modules/management/customers"));
const AddVendors = lazy(() => import("./modules/management/add_vendor"));
const Vendors = lazy(() => import("./modules/management/vendors"));

const SubCategories = lazy(() => import("./modules/catalog/sub_categories"));
const AddSubCategories = lazy(() =>
  import("./modules/catalog/add_sub_category")
);
const Categories = lazy(() => import("./modules/catalog/categories"));
const addCategories = lazy(() => import("./modules/catalog/add_category"));

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

const Products = lazy(() => import("./modules/management/products"));

const ServerError = lazy(() => import("./pages/500"));

const PrelovedOrder = lazy(()=> import("./modules/preloved/sales"));

const PrelovedProduct = lazy(()=> import("./modules/preloved/products"));

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);

  let routes = (
    <Switch>
      {
        /* Redirect from root URL to /dashboard. */
        <Redirect exact from="/" to="/dashboard" />
      }
      <ContentRoute path="/dashboard" component={DashboardPage} />
      <Route path="/user-profile" component={UserProfilepage} />
      <Route path="/roles/new" render={(props) => <div></div>} />
      <Route path="/roles/edit" render={(props) => <div></div>} />
      <Route path="/roles" render={(props) => <div></div>} />
      <Route path="/verification" render={(props) => <div></div>} />
      <Route path="/users/new" render={(props) => <div></div>} />
      <Route path="/users/edit" render={(props) => <div></div>} />
      <Route path="/users" render={(props) => <div></div>} />
      <Route path="/sub-categories/new" render={(props) => <div></div>} />
      <Route path="/sub-categories/edit" render={(props) => <div></div>} />
      <Route path="/sub-categories" render={(props) => <div></div>} />
      <Route path="/categories/new" render={(props) => <div></div>} />
      <Route path="/categories/edit" render={(props) => <div></div>} />
      <Route path="/categories" render={(props) => <div></div>} />
      <Route path="/banners/new" render={(props) => <div></div>} />
      <Route path="/banners/edit" render={(props) => <div></div>} />
      <Route path="/banners" render={(props) => <div></div>} />
      <Route path="/sales" render={(props) => <div></div>} />
      <Route path="/vendors/new" render={(props) => <div></div>} />
      <Route path="/vendors/edit" render={(props) => <div></div>} />
      <Route path="/vendors" render={(props) => <div></div>} />
      <Route path="/customers/view" render={(props) => <div></div>} />
      <Route path="/customers" render={(props) => <div></div>} />
      <Route path="/products" render={(props) => <div></div>} />
      <Route path="/server-error" render={(props) => <div></div>} />
      <Route path="/preloved-sales" render={(props) => <div></div>} />
      <Route path="/preloved-products" render={(props) => <div></div>} />
      <Redirect to="/dashboard" />
    </Switch>
  );
  if (user) {
    if (user.role_id === 1) {
      routes = (
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <Route path="/roles/new" component={AddRoles} />
          <Route path="/roles/edit" component={AddRoles} />
          <Route path="/roles" component={Roles} />
          <Route path="/users/new" component={AddUsers} />
          <Route path="/users/edit" component={AddUsers} />
          <Route path="/users" component={Users} />
          <Route path="/sub-categories/new" component={AddSubCategories} />
          <Route path="/sub-categories/edit" component={AddSubCategories} />
          <Route path="/sub-categories" component={SubCategories} />
          <Route path="/categories/new" component={addCategories} />
          <Route path="/categories/edit" component={addCategories} />
          <Route path="/categories" component={Categories} />
          <Route path="/banners/new" component={AddBanners} />
          <Route path="/banners/edit" component={AddBanners} />
          <Route path="/banners" component={Banners} />
          <Route path="/sales" component={Sales} />
          <Route path="/vendors/new" component={AddVendors} />
          <Route path="/vendors/edit" component={AddVendors} />
          <Route path="/vendors" component={Vendors} />
          <Route path="/verification" component={Verification} />
          <Route path="/customers/view" component={ViewCustomers} />
          <Route path="/customers" component={Customers} />
          <Route path="/products" component={Products} />
          <Route path="/user-profile" component={UserProfilepage} />
          <Route path="/server-error" component={ServerError} />
          <Route path="/preloved-sales" component={PrelovedOrder} />
          <Route path="/preloved-products" component={PrelovedProduct} />
          <Redirect to="/dashboard" />
        </Switch>
      );
    } else if ([1, 2].includes(user.role_id)) {
      routes = (
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <Route path="/users/new" component={AddUsers} />
          <Route path="/users/edit" component={AddUsers} />
          <Route path="/users" component={Users} />
          <Route path="/sub-categories/new" component={AddSubCategories} />
          <Route path="/sub-categories/edit" component={AddSubCategories} />
          <Route path="/sub-categories" component={SubCategories} />
          <Route path="/categories/new" component={addCategories} />
          <Route path="/categories/edit" component={addCategories} />
          <Route path="/categories" component={Categories} />
          <Route path="/sales" component={Sales} />
          <Route path="/banners/new" component={AddBanners} />
          <Route path="/banners/edit" component={AddBanners} />
          <Route path="/banners" component={Banners} />
          <Route path="/vendors/new" component={AddVendors} />
          <Route path="/vendors/edit" component={AddVendors} />
          <Route path="/vendors" component={Vendors} />
          <Route path="/verification" component={Verification} />
          <Route path="/customers/view" component={ViewCustomers} />
          <Route path="/customers" component={Customers} />
          <Route path="/products" component={Products} />
          <Route path="/user-profile" component={UserProfilepage} />
          <Route path="/server-error" component={ServerError} />
          <Route path="/preloved-sales" component={PrelovedOrder} />
          <Route path="/preloved-products" component={PrelovedProduct} />
          <Redirect to="/dashboard" />
        </Switch>
      );
    } else if ([1, 2, 3].includes(user.role_id)) {
      routes = (
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <Route path="/sales" component={Sales} />
          <Route path="/banners/new" component={AddBanners} />
          <Route path="/banners/edit" component={AddBanners} />
          <Route path="/banners" component={Banners} />
          <Route path="/vendors/new" component={AddVendors} />
          <Route path="/vendors/edit" component={AddVendors} />
          <Route path="/vendors" component={Vendors} />
          <Route path="/verification" component={Verification} />
          <Route path="/customers/view" component={ViewCustomers} />
          <Route path="/customers" component={Customers} />
          <Route path="/products" component={Products} />
          <Route path="/user-profile" component={UserProfilepage} />
          <Route path="/server-error" component={ServerError} />
          <Route path="/preloved-sales" component={PrelovedOrder} />
          <Route path="/preloved-products" component={PrelovedProduct} />
          <Redirect to="/dashboard" />
        </Switch>
      );
    }
  }
  return <Suspense fallback={<LayoutSplashScreen />}>{routes}</Suspense>;
}
