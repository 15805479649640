import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import {
  AiFillEyeInvisible,
  AiFillEye,
  AiFillCloseCircle
} from "react-icons/ai";

import { values } from "lodash";
// import * as auth from "../_redux/authRedux";
// import { login } from "../_redux/authCrud";
import { login } from "../../../redux/action/index";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: localStorage.getItem("email") ?? "",
  password: ""
};

function Login(props) {
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);
  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const { error } = useSelector(state => state.auth);
  const authLoading = useSelector(state => state.auth.loading);

  useEffect(() => {
    if (error) {
      disableLoading();
      formik.setSubmitting(false);
    }
  }, [error, authLoading]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      dispatch(login(values));
    }
    // (values, { setStatus, setSubmitting }) => {
    //   enableLoading();
    //   setTimeout(() => {
    //     login(values.email, values.password)
    //       .then(({ data: { authToken } }) => {
    //         disableLoading();

    //         props.login(authToken);
    //       })
    //       .catch(() => {
    //         setStatus(
    //           intl.formatMessage({
    //             id: "AUTH.VALIDATION.INVALID_LOGIN",
    //           })
    //         );
    //       })
    //       .finally(() => {
    //         disableLoading();
    //         setSubmitting(false);
    //       });
    //   }, 1000);
    // },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {error && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">
              <FormattedMessage id="AUTH.VALIDATION.INVALID_LOGIN" />
            </div>
          </div>
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container position-relative">
          <input
            placeholder="Password"
            type="password"
            type={passwordShown ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            autoComplete="off"
            {...formik.getFieldProps("password")}
          />
          <span
            className="position-absolute"
            style={{
              right: "2.5rem",
              top: "1rem",
              cursor: "pointer",
              fontSize: "1.5rem"
            }}
            onClick={() => togglePasswordVisiblity()}
          >
            {passwordShown ? <AiFillEye /> : <AiFillEyeInvisible />}
          </span>
          <span
            className="position-absolute"
            style={{
              right: "4.5rem",
              top: "1.5rem",
              cursor: "pointer",
              fontSize: "1rem"
            }}
            onClick={() => formik.setFieldValue("password", "")}
          >
            <AiFillCloseCircle />
          </span>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(Login);
