import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_customers: false,
  customers: [],
  error_customers: null,
  //
  loading_customer: false,
  customer_profile: false,
  customer_order: [],
  customer_error: false
};

const initGetCustomers = (state, action) => {
  return {
    ...state,
    loading_customers: action.loading_customers
  };
};
const successGetCustomers = (state, action) => {
  return {
    ...state,
    loading_customers: action.loading_customers,
    customers: action.customers
  };
};
const failGetCustomers = (state, action) => {
  return {
    ...state,
    loading_customers: action.loading_customers,
    error_customers: action.error_customers
  };
};
//
const initGetCustomer = (state, action) => {
  return {
    ...state,
    loading_customer: action.loading_customer
  };
};
const successGetCustomer = (state, action) => {
  return {
    ...state,
    loading_customer: action.loading_customer,
    customer_profile: action.customer_profile,
    customer_order: action.customer_order
  };
};
const failGetCustomer = (state, action) => {
  return {
    ...state,
    loading_customer: action.loading_customer,
    customer_error: action.customer_error,
    customer_profile: action.customer_profile
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CUSTOMERS:
      return initGetCustomers(state, action);
    case actionTypes.SUCCSESS_GET_CUSTOMERS:
      return successGetCustomers(state, action);
    case actionTypes.FAIL_GET_CUSTOMERS:
      return failGetCustomers(state, action);
    //
    case actionTypes.INIT_GET_CUSTOMER:
      return initGetCustomer(state, action);
    case actionTypes.SUCCSESS_GET_CUSTOMER:
      return successGetCustomer(state, action);
    case actionTypes.FAIL_GET_CUSTOMER:
      return failGetCustomer(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
