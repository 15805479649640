import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetVendors = () => {
  return {
    type: actionTypes.INIT_GET_VENDORS,
    loading_vendors: true,
  };
};

const successGetVendors = (data) => {
  return {
    type: actionTypes.SUCCSESS_GET_VENDORS,
    loading_vendors: false,
    active_vendors: data.active,
    inactive_vendors: data.inactive,
  };
};

const failGetVendors = (error) => {
  return {
    type: actionTypes.FAIL_GET_VENDORS,
    loading_vendors: false,
    error_vendors: error,
  };
};

export const getVendors = () => {
  const config = createConfig();
  return (dispatch) => {
    dispatch(initGetVendors());
    axios
      .get("vendor", config)
      .then((res) => dispatch(successGetVendors(res.data.data)))
      .catch((err) => dispatch(failGetVendors(err.message)));
  };
};

//Delete Vendor

export const deleteVendor = (id) => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);

  return (dispatch) => {
    axios
      .post("vendor", formData, config)
      .then((res) => dispatch(getVendors()))
      .catch((err) => console.log(err.message));
  };
};

//add Vendor

const initAddVendor = () => {
  return {
    type: actionTypes.INIT_ADD_VENDOR,
    adding_vendor: true,
  };
};
const successAddVendor = () => {
  return {
    type: actionTypes.SUCCSESS_ADD_VENDOR,
    adding_vendor: false,
    adding_vendor_sucess: true,
  };
};
const errorAddVendor = (error) => {
  return {
    type: actionTypes.FAIL_ADD_VENDOR,
    adding_vendor: false,
    adding_vendor_error: error,
  };
};

export const addVendor = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return (dispatch) => {
    dispatch(initAddVendor());
    axios
      .post("vendor", formData, config)
      .then((res) => {
        dispatch(getVendors());
        dispatch(successAddVendor());
        redirect("/vendors");
      })
      .catch((err) => {
        dispatch(errorAddVendor(err.response.data.errors));
        console.log(err, err.response);
      });
  };
};

//category detail:

const initGetVendor = () => {
  return {
    type: actionTypes.INIT_GET_VENDOR,
    loading_vendor: true,
  };
};
const successGetVendor = (data) => {
  console.log(data);
  let vendor_profile = {
    name: data.name,
    email: data.email,
    company_address_1: "",
    company_address_2: "",
    company_address_city: "",
    company_address_country: "",
    company_address_postcode: "",
    company_address_state: "",
    company_banner: "",
    company_email: "",
    company_latitude: "",
    company_logo: "",
    company_longitude: "",
    company_name: "",
    company_office_no: "",
    company_phone_no: "",
    company_register_no: "",
    account_no: "",
    account_name: "",
    bank_name: "",
  };
  if (data) {
    vendor_profile = {
      ...data,
    };
  }
  return {
    type: actionTypes.SUCCSESS_GET_VENDOR,
    loading_vendor: false,
    vendor_profile: vendor_profile,
  };
};
const failGetVendor = (error) => {
  return {
    type: actionTypes.FAIL_GET_VENDOR,
    loading_vendor: false,
    vendor_error: error,
  };
};

export const getVendorDetail = (id) => {
  const config = createConfig();
  return (dispatch) => {
    axios
      .get("vendor/view/" + id, config)
      .then((res) => dispatch(successGetVendor(res.data.data)))
      .catch((err) => console.log(err.message));
  };
};
