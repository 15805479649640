import axios from "axios";
import * as actionTypes from "./actionTypes";
import { getProfile } from "./index";

const initAuth = () => {
  return {
    type: actionTypes.INIT_AUTH,
    loading: true
  };
};

const successAuth = user => {
  return {
    type: actionTypes.SUCCESS_AUTH,
    loading: false,
    user: user
  };
};

const failAuth = error => {
  return {
    type: actionTypes.FAIL_AUTH,
    loading: false,
    error: error
  };
};

export const login = data => {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("password", data.password);

  return dispatch => {
    dispatch(initAuth());
    axios
      .post("login", formData)
      .then(res => {
        localStorage.setItem("idToken", res.data.access_token);
        // dispatch(successAuth(res.data.user))
        dispatch(getProfile());
      })
      .catch(err => dispatch(failAuth(err.message)));
  };
};

export const logout = () => {
  const token = localStorage.getItem("idToken");

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => [
    axios
      .post("logout", null, config)
      .then(res => {
        localStorage.removeItem("idToken");
        dispatch(successAuth(null));
      })
      .catch(err => console.log(err.message))
  ];
};
