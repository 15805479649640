import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_categories: false,
  active_categories: [],
  inactive_categories: [],
  error_categories: null,
  //
  loading_category: false,
  category_detail: null,
  category_error: null
};

const initGetCategories = (state, action) => {
  return {
    ...state,
    loading_categories: action.loading_categories
  };
};
const successGetCategories = (state, action) => {
  return {
    ...state,
    loading_categories: action.loading_categories,
    active_categories: action.active_categories,
    inactive_categories: action.inactive_categories
  };
};
const failGetCategories = (state, action) => {
  return {
    ...state,
    loading_categories: action.loading_categories,
    error_categories: action.error_categories
  };
};

const initGetCategory = (state, action) => {
  return {
    ...state,
    loading_category: action.loading_category
  };
};
const successGetCategory = (state, action) => {
  return {
    ...state,
    loading_category: action.loading_category,
    category_detail: action.category_detail
  };
};
const failGetCategory = (state, action) => {
  return {
    ...state,
    loading_category: action.loading_category,
    category_error: action.category_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_CATEGORIES:
      return initGetCategories(state, action);
    case actionTypes.SUCCSESS_GET_CATEGORIES:
      return successGetCategories(state, action);
    case actionTypes.FAIL_GET_CATEGORIES:
      return failGetCategories(state, action);
    //
    case actionTypes.INIT_GET_CATEGORY:
      return initGetCategory(state, action);
    case actionTypes.SUCCSESS_GET_CATEGORY:
      return successGetCategory(state, action);
    case actionTypes.FAIL_GET_CATEGORY:
      return failGetCategory(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
