import axios from "axios";
import { createConfig } from "../utils";
import * as actionTypes from "./actionTypes";

const initGetApplications = () => {
  return {
    type: actionTypes.INIT_GET_APPLICATIONS,
    loading_applications: true,
  };
};

const successGetApplications = (data) => {
  return {
    type: actionTypes.SUCCSESS_GET_APPLICATIONS,
    loading_applications: false,
    data_applications: data.data,
    pagination_applicatoin: {
      page: data.current_page,
      sizePerPage: data.per_page,
      totalSize: data.total,
    },
    error_applications: null,
  };
};

const failGetApplications = (error) => {
  return {
    type: actionTypes.FAIL_GET_APPLICATIONS,
    loading_applications: false,
    error_applications: error,
  };
};

export const getApplications = () => {
  const config = createConfig();
  return (dispatch, getState) => {
    const { sort, search, page } = getState().kyc.remote_params;
    dispatch(initGetApplications());
    axios
      .get(
        `vendor/getNotVerifiedVendor?sort=${sort}&search=${search}&page=${page}`,
        config
      )
      .then((res) => dispatch(successGetApplications(res.data.data)))
      .catch((err) => dispatch(failGetApplications(err.message)));
  };
};

//update params
export const updateKycParams = (params) => {
  return {
    type: actionTypes.UPDATE_KYC_PARAMS,
    params,
  };
};

//update application
export const updateApplication = (data, addToast) => {
  const config = createConfig();

  return (dispatch) => {
    axios
      .put("vendor/verfiedVendor", data, config)
      .then((res) => {
        dispatch(getApplications());
        addToast(data.is_verified ? "Approved" : "Rejected", {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err.message, err.response);
        addToast("Error", {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
};
