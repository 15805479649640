import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_products: false,
  data_products: [],
  error_products: null
};

const initGetProducts = (state, action) => {
  return {
    ...state,
    loading_products: action.loading_products
  };
};
const successGetProducts = (state, action) => {
  return {
    ...state,
    loading_products: action.loading_products,
    data_products: action.data_products
  };
};
const failGetProducts = (state, action) => {
  return {
    ...state,
    loading_products: action.loading_products,
    error_products: action.error_products
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_PRODUCTS:
      return initGetProducts(state, action);
    case actionTypes.SUCCSESS_GET_PRODUCTS:
      return successGetProducts(state, action);
    case actionTypes.FAIL_GET_PRODUCTS:
      return failGetProducts(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
