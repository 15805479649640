/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router";
import { toAbsoluteUrl } from "../../../_helpers";

export function LinkNtitle({ className, widgetHeight = "175px", to, title }) {
  const history = useHistory();
  let backgroundImageUrl = toAbsoluteUrl("/media/svg/icons/Tools/Axe.svg"),
    backgroundColor = "#663259";
  if (title === "Manage Sales") {
    backgroundImageUrl = toAbsoluteUrl("/media/svg/icons/Tools/Hummer.svg");
    backgroundColor = "#38394a";
  }

  return (
    <>
      <div
        className={`card card-custom bgi-no-repeat ${className}`}
        style={{
          height: widgetHeight,
          backgroundColor: backgroundColor,
          backgroundPosition: "calc(100% + 0.5rem) 100%",
          backgroundSize: "100% auto",
          backgroundImage: `url("${backgroundImageUrl}")`
        }}
      >
        {/* begin::Body */}
        <div className="card-body d-flex align-items-center">
          <div>
            <h3 className="text-white font-weight-bolder line-height-lg mb-5">
              {title}
            </h3>
            <a
              className="btn btn-success font-weight-bold px-6 py-3"
              onClick={e => {
                e.preventDefault();
                history.push(to);
              }}
            >
              Start
            </a>
          </div>
        </div>
        {/* end::Body */}
      </div>
    </>
  );
}
