import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_vendors: false,
  active_vendors: [],
  inactive_vendors: [],
  error_vendors: null,
  //
  adding_vendor_error: null,
  //
  loading_vendor: false,
  vendor_profile: null,
  vendor_error: null
};

const initGetVendors = (state, action) => {
  return {
    ...state,
    loading_vendors: action.loading_vendors
  };
};
const successGetVendors = (state, action) => {
  return {
    ...state,
    loading_vendors: action.loading_vendors,
    active_vendors: action.active_vendors,
    inactive_vendors: action.inactive_vendors
  };
};
const failGetVendors = (state, action) => {
  return {
    ...state,
    loading_vendors: action.loading_vendors,
    error_vendors: action.error_vendors
  };
};
//
const failAddVendor = (state, action) => {
  return {
    ...state,
    adding_vendor_error: action.adding_vendor_error
  };
};
//
const initGetVendor = (state, action) => {
  return {
    ...state,
    loading_vendor: action.loading_vendor
  };
};
const successGetVendor = (state, action) => {
  return {
    ...state,
    loading_vendor: action.loading_vendor,
    vendor_profile: action.vendor_profile
  };
};
const failGetVendor = (state, action) => {
  return {
    ...state,
    loading_vendor: action.loading_vendor,
    vendor_error: action.vendor_error
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_VENDORS:
      return initGetVendors(state, action);
    case actionTypes.SUCCSESS_GET_VENDORS:
      return successGetVendors(state, action);
    case actionTypes.FAIL_GET_VENDORS:
      return failGetVendors(state, action);
    //
    case actionTypes.FAIL_ADD_VENDOR:
      return failAddVendor(state, action);
    //
    case actionTypes.INIT_GET_VENDOR:
      return initGetVendor(state, action);
    case actionTypes.SUCCSESS_GET_VENDOR:
      return successGetVendor(state, action);
    case actionTypes.FAIL_GET_VENDOR:
      return failGetVendor(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
