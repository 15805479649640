import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetCategories = () => {
  return {
    type: actionTypes.INIT_GET_CATEGORIES,
    loading_categories: true
  };
};

const successGetCategories = data => {
  return {
    type: actionTypes.SUCCSESS_GET_CATEGORIES,
    loading_categories: false,
    active_categories: data.active,
    inactive_categories: data.inactive
  };
};

const failGetCategories = error => {
  return {
    type: actionTypes.FAIL_GET_CATEGORIES,
    loading_categories: false,
    error_categories: error
  };
};

export const getCategories = () => {
  const token = localStorage.getItem("idToken");

  const config = {
    headers: {
      Authorization: "Bearer " + token
    }
  };
  return dispatch => {
    dispatch(initGetCategories());
    axios
      .get("category", config)
      .then(res => dispatch(successGetCategories(res.data.data)))
      .catch(err => dispatch(failGetCategories(err.message)));
  };
};

//Delete Category

export const deleteCategory = id => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);

  return dispatch => {
    axios
      .post("category", formData, config)
      .then(res => dispatch(getCategories()))
      .catch(err => console.log(err.message));
  };
};

// add Category

export const addCategory = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("sort", 1);
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return dispatch => {
    axios
      .post("category", formData, config)
      .then(res => {
        dispatch(getCategories());
        redirect("/categories");
      })
      .catch(err => console.log(err.message, err.response));
  };
};

//category detail:

const initGetCategory = () => {
  return {
    type: actionTypes.INIT_GET_CATEGORY,
    loading_category: true
  };
};
const successGetCategory = data => {
  return {
    type: actionTypes.SUCCSESS_GET_CATEGORY,
    loading_category: false,
    category_detail: data
  };
};
const failGetCategory = error => {
  return {
    type: actionTypes.FAIL_GET_CATEGORY,
    loading_category: false,
    category_error: error
  };
};

export const getCategoryDetail = id => {
  const config = createConfig();
  return dispatch => {
    axios
      .get("category/view/" + id, config)
      .then(res => dispatch(successGetCategory(res.data.data)))
      .catch(err => console.log(err.message));
  };
};
