import React, { useEffect } from "react";
import { SalesStatus } from "../widgets/adminWidgets/salesStatus";
import { RecentList } from "../widgets/adminWidgets/recentList";
import { RecentPepole } from "../widgets/adminWidgets/recentPepole";
import { SuperAdmin } from "../widgets/adminWidgets/superAdmin";
import { ProductCount } from "../widgets/adminWidgets/productCount";
import { NewPepole } from "../widgets/adminWidgets/newPepole";
import { LinkNtitle } from "../widgets/adminWidgets/linkNtitle";
import { LayoutSplashScreen } from "../../layout";

import { useDispatch, useSelector } from "react-redux";
import { getDashoard } from "../../../redux/action";

const customers = [
  {
    id: 4,
    name: "Test User #4",
    email: "testuser_4@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 5,
    name: "Test User #5",
    email: "testuser_5@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 6,
    name: "Test User #6",
    email: "testuser_6@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 7,
    name: "Test User #7",
    email: "testuser_7@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 8,
    name: "Test User #8",
    email: "testuser_8@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 9,
    name: "Test User #9",
    email: "testuser_9@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 10,
    name: "Test User #10",
    email: "testuser_10@gmail.com",
    created_at: null,
    updated_at: null
  },
  {
    id: 11,
    name: "Test User #11",
    email: "testuser_11@gmail.com",
    created_at: null,
    updated_at: null
  }
];

export function DashboardAdmin() {
  const dispatch = useDispatch();
  const {
    loading_dashboard,
    error_dashboard,
    sales,
    product,
    status
  } = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(getDashoard());
  }, []);
  return loading_dashboard ? (
    <LayoutSplashScreen visible={true} />
  ) : (
    <>
      {/* begin::Dashboard */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-4">
          <SalesStatus
            className="gutter-b card-stretch"
            chartColor="danger"
            sales={sales}
            salesStatus={status}
          />
        </div>
        <div className="col-xl-8">
          <div className="row">
            <div className="col-xl-12">
              <SuperAdmin className="gutter-b" widgetHeight="170px" />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-6">
              <LinkNtitle
                title="Manage Categories"
                className="gutter-b"
                widgetHeight="175px"
                to="/categories"
              />
              <div className="row">
                <div className="col-xl-6">
                  <ProductCount
                    count={0}
                    type="Product"
                    className="gutter-b"
                    baseColor="primary"
                    widgetHeight="200px"
                  />
                </div>
                <div className="col-xl-6">
                  <NewPepole
                    type="Vendors"
                    count={0}
                    className="gutter-b"
                    iconColor="success"
                    widgetHeight="200px"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-6">
                  <ProductCount
                    count={0}
                    type="Purchase"
                    className="gutter-b"
                    baseColor="warning"
                    widgetHeight="200px"
                  />
                </div>
                <div className="col-xl-6">
                  <NewPepole
                    type="Customers"
                    count={0}
                    className="gutter-b"
                    iconColor="info"
                    widgetHeight="200px"
                  />
                </div>
              </div>
              <LinkNtitle
                title="Manage Sales"
                className="gutter-b"
                widgetHeight="175px"
                to="/sales"
              />
            </div>
          </div>
        </div>
      </div>
      {/* end::Row */}

      {/* begin::Row */}
      <div className="row">
        <div className="col-xl-6">
          <RecentList list={product} />
        </div>
        {/* <div className="col-xl-3">
          <RecentPepole type="Vendors" pepole={customers} />
        </div>
        <div className="col-xl-3">
          <RecentPepole type="Customers" pepole={customers} />
        </div> */}
      </div>
      {/* end::Row */}
    </>
  );
}
