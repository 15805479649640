import * as actionTypes from "../action/actionTypes";

const initState = {
  loading_dashboard: false,
  sales: null,
  product: [],
  status: null,
  error_dashboard: null
  //   loading_dashboard: false,
  //   active_categories: [],
  //   inactive_categories: [],
  //   error_dashboard: null,
};

const initGetDashboard = (state, action) => {
  return {
    ...state,
    loading_dashboard: action.loading_dashboard
  };
};
const successGetDashboard = (state, action) => {
  return {
    ...state,
    loading_dashboard: action.loading_dashboard,
    sales: action.sales,
    product: action.product,
    status: action.status
  };
};
const failGetDashboard = (state, action) => {
  return {
    ...state,
    loading_dashboard: action.loading_dashboard,
    error_dashboard: action.error_dashboard
  };
};

const Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_GET_DASHBOARD:
      return initGetDashboard(state, action);
    case actionTypes.SUCCSESS_GET_DASHBOARD:
      return successGetDashboard(state, action);
    case actionTypes.FAIL_GET_DASHBOARD:
      return failGetDashboard(state, action);
    default:
      return { ...state };
  }
};

export default Reducer;
