//Auth
export { login, logout } from "./authActions";
//Categories
export {
  getCategories,
  deleteCategory,
  addCategory,
  getCategoryDetail
} from "./categoriesAction";
//sub categories:
export {
  getSubCategories,
  addSubCategory,
  deleteSubCategory,
  getSubCategoryDetail
} from "./sub_categoriesAction";
export {
  getVendors,
  addVendor,
  deleteVendor,
  getVendorDetail
} from "./vendorsAction";
export { getCustomers, getCustomerDetail } from "./customersAction";
export { getUsers, addUser, deleteUser, getUserDetail } from "./usersAction";
export { getRoles, addRole, deleteRole, getRoleDetail } from "./rolesAction";
export { getSales, getSaleDetail } from "./salesAction";
export {
  getProfile,
  updateProfile,
  updateProfilePassword
} from "./profileAction";
export { getDashoard } from "./dashboardAction";
export {
  getBanners,
  getBnnerDetail,
  deleteBanner,
  addBanner
} from "./bannerAction";
export { getProducts, updateProduct } from "./productsAction";
export { getApplications, updateApplication } from "./kycActions";
