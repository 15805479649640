import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetUsers = () => {
  return {
    type: actionTypes.INIT_GET_USERS,
    loading_users: true
  };
};

const successGetUsers = data => {
  // console.log(data)
  const all_users_arr = [];
  Object.entries(data).map(([key, val]) => {
    if (key !== "other") {
      val.forEach(user => {
        user.role_name = key;
        all_users_arr.push(user);
      });
    }
  });
  Object.entries(data.other).map(([key, val]) => {
    val.forEach(user => {
      user.role_name = key;
      all_users_arr.push(user);
    });
  });
  let other = data.other;
  delete data.other;

  return {
    type: actionTypes.SUCCSESS_GET_USERS,
    loading_users: false,
    all_users: all_users_arr,
    data: data,
    other: other
  };
};

const failGetUsers = error => {
  return {
    type: actionTypes.FAIL_GET_USERS,
    loading_users: false,
    error_users: error
  };
};

export const getUsers = () => {
  const config = createConfig();
  return dispatch => {
    dispatch(initGetUsers());
    axios
      .get("user", config)
      .then(res => dispatch(successGetUsers(res.data.data)))
      .catch(err => dispatch(failGetUsers(err.message)));
  };
};

//Delete user

export const deleteUser = id => {
  const config = createConfig();
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);

  return dispatch => {
    axios
      .post("user", formData, config)
      .then(res => dispatch(getUsers()))
      .catch(err => console.log(err.message));
  };
};

//add User

const initAddUser = () => {
  return {
    type: actionTypes.INIT_ADD_USER,
    adding_user: true
  };
};
const successAddUser = () => {
  return {
    type: actionTypes.SUCCSESS_ADD_USER,
    adding_user: false,
    adding_user_sucess: true
  };
};
const errorAddUser = error => {
  return {
    type: actionTypes.FAIL_ADD_USER,
    adding_user: false,
    adding_user_error: error
  };
};

export const addUser = (data, redirect) => {
  const config = createConfig();
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return dispatch => {
    dispatch(initAddUser());
    axios
      .post("user", formData, config)
      .then(res => {
        dispatch(getUsers());
        dispatch(successAddUser());
        redirect({ pathname: "/users", state: "all_users" });
      })
      .catch(err => {
        console.log(err, err.response);
        if (err.response) dispatch(errorAddUser(err.response.data.errors));
        else dispatch(failGetUser("Error"));
      });
  };
};

//user detail:

const initGetUser = () => {
  return {
    type: actionTypes.INIT_GET_USER,
    loading_user: true
  };
};
const successGetUser = data => {
  return {
    type: actionTypes.SUCCSESS_GET_USER,
    loading_user: false,
    user_profile: {
      ...data.profile,
      active: data.active,
      role_id: data.role_id,
      name: data.name,
      email: data.email
    },
    user_error: null
  };
};
const failGetUser = error => {
  return {
    type: actionTypes.FAIL_GET_USER,
    loading_user: false,
    user_error: error
  };
};

export const getUserDetail = id => {
  const config = createConfig();
  return dispatch => {
    axios
      .get("user/view/" + id, config)
      .then(res => dispatch(successGetUser(res.data.data)))
      .catch(err => console.log(err.message));
  };
};
