import axios from "axios";
import { createConfig } from "../utils";

import * as actionTypes from "./actionTypes";

const initGetCustomers = () => {
  return {
    type: actionTypes.INIT_GET_CUSTOMERS,
    loading_customers: true
  };
};

const successGetCustomers = data => {
  return {
    type: actionTypes.SUCCSESS_GET_CUSTOMERS,
    loading_customers: false,
    customers: data
  };
};

const failGetCustomers = error => {
  return {
    type: actionTypes.FAIL_GET_CUSTOMERS,
    loading_customers: false,
    error_customers: error
  };
};

export const getCustomers = () => {
  const config = createConfig();
  return dispatch => {
    dispatch(initGetCustomers());
    axios
      .get("customer", config)
      .then(res => dispatch(successGetCustomers(res.data.data)))
      .catch(err => dispatch(failGetCustomers(err.message)));
  };
};

//customer detail:

const initGetCustomer = () => {
  return {
    type: actionTypes.INIT_GET_CUSTOMER,
    loading_customer: true
  };
};
const successGetCustomer = data => {
  return {
    type: actionTypes.SUCCSESS_GET_CUSTOMER,
    loading_customer: false,
    customer_profile: data.profile,
    customer_order: data.order
  };
};
const failGetCustomer = error => {
  return {
    type: actionTypes.FAIL_GET_CUSTOMER,
    loading_customer: false,
    customer_error: error,
    customer_profile: {
      email: null,
      phone: null
    }
  };
};

export const getCustomerDetail = id => {
  const config = createConfig();
  return dispatch => {
    axios
      .get("/customer/view/" + id, config)
      .then(res => dispatch(successGetCustomer(res.data.data)))
      .catch(err => {
        dispatch(failGetCustomer(err));
        console.log(err.message);
      });
  };
};
